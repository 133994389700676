import { Box, Flex, Text } from "@chakra-ui/react";
import chainGet from "src/services/network/chainGet";
import { CallStatusSchedulerType } from "src/hooks/queries/account/useCallStatusSchedulersQuery";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";

type Props = {
  selectedStatusState: [
    CallStatusSchedulerType | null,
    React.Dispatch<React.SetStateAction<CallStatusSchedulerType | null>>
  ];
  lastCallStatusItem: CallStatusSchedulerType;
  scheduledStatus: CallStatusSchedulerType;
  // isLastItemSelected: boolean;
  [key: string]: any;
};

const ScheduledStatusConflictCard = ({
  scheduledStatus,
  selectedStatusState,
  lastCallStatusItem,
  ...rest
}: Props) => {
  const [selectedStatus, setSelectedStatus] = selectedStatusState;

  const isLastItemSelected = lastCallStatusItem?.id === selectedStatus?.id;
  const isNotLastItemSelected = !isLastItemSelected;

  const id = scheduledStatus.id;
  const changeStatusTo = chainGet(scheduledStatus?.changeStatusTo, null);

  const isTakingCalls = changeStatusTo === "Yes";
  const isNotTakingCalls = changeStatusTo === "No";
  const isEmergencyCalls = changeStatusTo === "Emergency Calls Only";

  const getBorderLeftColor = () => {
    if (isEmergencyCalls) return "#ED8936";
    return isTakingCalls ? "#2abd1d" : "#E80B0B";
  };

  const statusNote = chainGet(scheduledStatus?.statusNote, "") as string;
  const unavailabilityExplanation = chainGet(
    scheduledStatus?.unavailabilityExplanation,
    ""
  );
  const unavailablityExplanationOther = chainGet(
    scheduledStatus?.unavailabilityExplanationOther,
    ""
  ) as string;

  const applyStatusOn = chainGet(scheduledStatus?.applyStatusOn, null);
  const revertStatusOn = chainGet(scheduledStatus?.revertStatusOn, null);

  const connectOnly = chainGet(scheduledStatus?.connectOnly, null);
  const doNotConnect = chainGet(scheduledStatus?.doNotConnect, null);

  const { formatDateStringInMyTimeZone, getMyTimeZoneString } =
    useFormatDateInMyTimeZone();
  const formattedEndTime =
    revertStatusOn && formatDateStringInMyTimeZone(revertStatusOn, false);
  const formattedStartTime =
    applyStatusOn && formatDateStringInMyTimeZone(applyStatusOn, false);

  const renderStatusLabel = () => {
    if (isEmergencyCalls) {
      return "Emergency Calls Only";
    }

    if (isNotTakingCalls && unavailabilityExplanation) {
      if (unavailabilityExplanation === "Other") {
        return `${unavailablityExplanationOther} - Unavailable`;
      }

      if (unavailabilityExplanation === "Unavailable") {
        return "Unavailable";
      }

      return `${unavailabilityExplanation} - Unavailable`;
    }

    if (isNotTakingCalls) return "Unavailable";

    if (isTakingCalls) return `Available`;

    return "Unknown Status";
  };

  const borderLeftColor = getBorderLeftColor();

  const connectOnlyString =
    typeof connectOnly === "string" ? connectOnly.split(";").join(", ") : null;

  const doNotConnectString =
    typeof doNotConnect === "string"
      ? doNotConnect.split(";").join(", ")
      : null;

  const getDateTimeLabel = () => {
    if (formattedStartTime && !formattedEndTime) {
      return `${formattedStartTime} ${getMyTimeZoneString()} - Until further notice`;
    }

    if (!formattedStartTime && formattedEndTime)
      return `Expires at: ${
        formattedEndTime || null
      } ${getMyTimeZoneString()} `;

    if (formattedStartTime && formattedEndTime) {
      const startTimeTimeZone = getMyTimeZoneString();
      const endTimeTimeZone = getMyTimeZoneString();

      if (startTimeTimeZone === endTimeTimeZone) {
        return `${formattedStartTime} - ${formattedEndTime} ${startTimeTimeZone}`;
      }

      return `${formattedStartTime} ${startTimeTimeZone} - ${formattedEndTime} ${endTimeTimeZone}`;
    }
  };

  const getBorder = () => {
    if (isLastItemSelected && selectedStatus?.id === id) {
      return "2px solid #333";
    }

    if (
      isNotLastItemSelected &&
      scheduledStatus?.id !== lastCallStatusItem?.id
    ) {
      return "2px solid #333";
    }

    return "none";
  };

  return (
    <Flex
      border={getBorder()}
      onClick={() => {
        setSelectedStatus(scheduledStatus);
      }}
      cursor="pointer"
      w="100%"
      h="179px"
      px="21px"
      py="16px"
      borderRadius="5px"
      borderWidth="1px"
      borderLeftWidth={{ base: "12px", md: "6px" }}
      borderLeftColor={borderLeftColor}
      borderLeftStyle="solid"
      bg="#fff"
      flexDir={{ base: "column", sm: "row" }}
      flexWrap="wrap"
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      pos="relative"
      {...rest}
    >
      <Box w={"100%"}>
        <Text fontWeight="bold" fontSize={{ base: "20px", md: "22px" }}>
          {renderStatusLabel()}
        </Text>
        <Text
          color="#9b9b9b"
          mt="5px"
          fontSize="14px"
          fontWeight={700}
          wordBreak="break-all"
        >
          {getDateTimeLabel()}
          {/* Expires at: {formattedRevertStatusData || null} */}
        </Text>
        {connectOnly && (isNotTakingCalls || isEmergencyCalls) && (
          <Text
            color="#9b9b9b"
            mt="5px"
            fontSize="14px"
            fontWeight={400}
            wordBreak="break-word"
            overflowWrap="break-word"
          >
            Connect Only: {connectOnlyString}
          </Text>
        )}

        {doNotConnect && isTakingCalls && (
          <Text color="#9b9b9b" mt="5px" fontSize="14px" fontWeight={400}>
            Do not connect: {doNotConnectString}
          </Text>
        )}

        {statusNote && (
          <Text color="#9b9b9b" mt="5px" fontSize="14px" fontWeight={400}>
            Note: {statusNote}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default ScheduledStatusConflictCard;
