import entities from "src/constants/entities";
import { QueryKeyType } from "src/hooks/queries/paginationQuery/useDataWithPagination";
import { InvoicePreviewPayloadType } from "../invoices/useInvoicePreview";

export type SubscriptionsOptionsType = {
  businessAccountId: string | null | undefined;
  companyId: string | null | undefined;
};

export type CreateSubscriptionPayload = {
  contactId: string;
  companyId: string;
  // packages: Array<{ id: string; vmBoxCode?: string; oppId?: string }>;
  packages: InvoicePreviewPayloadType[];
};

export type CreateSubscriptionOptionsType = {
  businessAccountId: string | null | undefined;
};

type SubscriptionsKeysFactoryType = (
  options: SubscriptionsOptionsType
) => QueryKeyType<SubscriptionsOptionsType>;
type CreateSubscriptionKeysFactoryType = (
  options: CreateSubscriptionOptionsType
) => QueryKeyType<CreateSubscriptionOptionsType>;

export const getSubscriptionsKeys: SubscriptionsKeysFactoryType = (
  options: SubscriptionsOptionsType
) => [
  entities.SUBSCRIPTIONS,
  {
    ...options,
  },
];
export const getNewSubscriptionKeys: CreateSubscriptionKeysFactoryType = (
  options: CreateSubscriptionOptionsType
) => [
  entities.SUBSCRIPTION_PLANS,
  {
    ...options,
  },
];
export const getActiveSubscriptionsKeys: SubscriptionsKeysFactoryType = (
  options: SubscriptionsOptionsType
) => [
  entities.ACTIVE_SUBSCRIPTIONS,
  {
    ...options,
  },
];

export default getSubscriptionsKeys;
