import { ComponentType } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterSpinner from "src/components/shared/CenterSpinner";
import useIsSubAccountType from "src/hooks/useIsSubAccountType";
import { useUserStatus } from "src/store/userStatusState";

/* eslint-disable react/display-name */
const withSubAccountRedirect =
  (Component: ComponentType) => (props: Record<string, any>) => {
    const navigate = useNavigate();
    const isSubAccountType = useIsSubAccountType();
    const { overrideSubscriptionPagePermissions } = useUserStatus();
    const override =
      overrideSubscriptionPagePermissions &&
      "show" === overrideSubscriptionPagePermissions.toLowerCase();

    useEffect(() => {
      if (isSubAccountType && !override) {
        navigate("/dashboard");
        return;
      }
    }, [isSubAccountType, override, navigate]);

    return isSubAccountType && !override ? (
      <Flex h={"100vh"} justify={"center"} align={"center"}>
        <Text mr={4}> Redirecting Please Wait...</Text>
        <CenterSpinner />
      </Flex>
    ) : (
      <Component {...props} />
    );
  };

export default withSubAccountRedirect;
