import {
  Popover,
  PopoverContent,
  useDisclosure,
  Stack,
  Text,
  useOutsideClick,
  useMediaQuery,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
} from "@chakra-ui/react";
import { StatusLabelKeysType, useUserStatus } from "src/store/userStatusState";
import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { updateStatus } from "src/services/data/status";
import { useCompanySelection } from "src/store/companySelectionState";
import StatusMenuWidget from "./StatusMenuWidget";
import StatusMenuContext from "./StatusMenuContext";
import useCurrentCallStatusQuery from "src/hooks/queries/account/useCurrentCallStatusQuery";
import useContactCallStatusTemplates from "src/hooks/queries/settings/useContactCallStatusTemplates";
import useAddCallStatusTemplate from "src/hooks/mutations/account/useAddCallStatusTemplate";
import breakpoints from "src/constants/breakpoints";
import TransferToModal from "./TransferToModal";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";
import useAddCallStatusSchedulers, {
  AddNewScheduledCallStatusTemplateType,
  AddScheduledCallStatusType,
} from "src/hooks/mutations/account/useAddCallStatusSchedulers";
import { AxiosError } from "axios";
import { TDateISO } from "src/types";
import { CallStatusSchedulerType } from "src/hooks/queries/account/useCallStatusSchedulersQuery";
import CallStatusConflictModal from "src/components/modules/account/subComponents/CallStatuses/CallStatusConflictModal";

const REFRESH_INTERVAL_DELAY = 60000;

export const statusLabels = {
  Online: "Available",
  EMERGENCY_CALLS_ONLY: "Emergency Calls Only",
  Offline: "Unavailable",
  Template: "Call Status Template",
};

const StatusMenu = () => {
  const {
    status,
    transferTo,
    setStatus,
    statusExpireAtUTC,
    setStatusExpireAtUTC,
    setTransferTo,
    fetch,
    // Advanced
    connectOnly,
    doNotConnect,
    currentUnavailabilityExplanation,
    currentUnavailabilityExplanationOther,
    currentStatusNote,
    //
    callStatusTemplateId,
    // Popover state
    onOpen,
    onClose,
    isOpen,
    isOpenExternal,
  } = useUserStatus();

  // Templates
  const { data: templates, isLoading: isTemplatesLoading } =
    useContactCallStatusTemplates({ size: 1000 });

  const onCreateStatusTemplateSuccess = async ({ data }: any) => {
    const newTemplateId = data.id;

    if (activeCompanyObject) {
      setIsLoading(true);

      if ("later" === statusStartSelect) {
        const payload: Partial<AddNewScheduledCallStatusTemplateType> = {
          callStatusTemplateId: newTemplateId,
        };
        if (customStart) {
          payload.applyStatusOn = new Date(
            customStart
          ).toISOString() as TDateISO;
        }
        if (nextExpireAtUTC) {
          payload.revertStatusOn = new Date(
            nextExpireAtUTC
          ).toISOString() as TDateISO;
        }

        try {
          await mutateAsync(payload as AddNewScheduledCallStatusTemplateType);
        } catch (error) {
          const response = (error as AxiosError).response;
          const status = response?.status;
          const message = response?.data
            ?.message as Array<CallStatusSchedulerType>;

          if (status === 409 && message.length >= 2) {
            setCallStatusConflict(message);
            setIsLoading(false);
            onClose();
            return;
          }

          return false;
        }

        return true;
      }

      updateStatus(
        activeCompanyObject.id,
        activeCompanyObject.contactId,
        null,
        nextExpireAtUTC,
        transferToInternal || null,
        null,
        null,
        null,
        null,
        null,
        newTemplateId
      ).then(() => {
        fetchStatus().then(() => {
          setIsLoading(false);
          setIsSaveAsTemplate(false);
          setCreateTemplateName("");
          refetchStatusPage();
          setErrors({});
          onClose();
        });
      });
    }
  };

  const {
    mutateAsync: mutateCreateStatusTemplate,
    isLoading: isCreateStatusTemplateLoading,
  } = useAddCallStatusTemplate({
    onSuccess: onCreateStatusTemplateSuccess,
  });

  // Update status page
  const { refetch: refetchStatusPage } = useCurrentCallStatusQuery();

  const [refreshCounter, setRefreshCounter] = useState(0);

  const { isOpen: isOpenAdvance, onToggle: onToggleAdvance } = useDisclosure();

  const {
    isOpen: isOpenTransferToModal,
    onClose: onCloseTransferToModal,
    onOpen: onOpenTransferToModal,
  } = useDisclosure();

  const { activeCompanyObject } = useCompanySelection();

  const [statusInternal, setStatusInternal] =
    useState<StatusLabelKeysType>("Offline");

  const [statusStartSelect, setStatusStartSelect] = useState<string>("");
  const [statusExpireSelect, setStatusExpireSelect] = useState<string>("");

  const [transferToInternal, setTransferToInternal] = useState<string>("");

  const [errors, setErrors] = useState<any>({});

  const [customStart, setCustomStart] = useState<string>("");
  const [customExpire, setCustomExpire] = useState<string>("");

  const [isInitLoading, setIsInitLoading] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Advanced settings

  const [callers, setCallers] = useState<Array<string>>([""]);

  const [reason, setReason] = useState<string>("");

  const [otherReason, setOtherReason] = useState<string>("");

  const [note, setNote] = useState<string>("");

  const [templateIdInternal, setTemplateIdInternal] = useState<string | null>(
    null
  );

  const [createTemplateName, setCreateTemplateName] = useState("");

  const [isSaveAsTemplate, setIsSaveAsTemplate] = useState(false);

  const onModalCloseAndReset = () => {
    onClose();
    setCustomStart("");
    setCustomExpire("");
    setStatusStartSelect("");
    setStatusExpireSelect("");
    setNote(currentStatusNote || "");
    setIsSaveAsTemplate(false);
    setCreateTemplateName("");
    applyCallers();
  };

  const popoverContextRef = useRef<any>(null);

  const modalContextRef = useRef<any>(null);

  useOutsideClick({
    ref: popoverContextRef,
    handler: () => {
      if (isOpen && !isOpenExternal) {
        onModalCloseAndReset();
      }
    },
  });

  useOutsideClick({
    ref: modalContextRef,
    handler: () => {
      if (isOpen && isOpenExternal) {
        onModalCloseAndReset();
      }
    },
  });

  useEffect(() => {
    setStatusInternal(status);
  }, [status]);

  // POC: preserve expiration during status and template changes
  // useEffect(() => {
  //   setStatusExpireSelect(
  //     (
  //       statusInternal !== "Template"
  //         ? statusInternal !== status || callStatusTemplateId
  //         : templateIdInternal !== callStatusTemplateId
  //     )
  //       ? "reset"
  //       : ""
  //   );
  // }, [statusInternal, status, templateIdInternal, callStatusTemplateId]);

  useEffect(() => {
    setTransferToInternal("");
    setReason("");
    setOtherReason("");

    setErrors({});
    setNote("");
    if (statusInternal !== "Template") {
      setTemplateIdInternal(null);
    }
  }, [statusInternal]);

  useEffect(() => {
    if ("later" === statusStartSelect) {
      setCustomStart("");
    }
  }, [statusStartSelect]);

  useEffect(() => {
    if (statusExpireSelect !== "custom") {
      setCustomExpire("");
    }
  }, [statusExpireSelect]);

  useEffect(() => {
    if (isOpen && !isLoading) {
      setStatusStartSelect("");
      setStatusExpireSelect("");
      setStatusInternal(!callStatusTemplateId ? status : "Template");
      setIsSaveAsTemplate(false);
      setCreateTemplateName("");
      fetchStatus();
    }
    // Close advance
    if (isOpenAdvance) {
      onToggleAdvance();
    }
  }, [isOpen]);

  // Advanced listen

  const applyCallers = () => {
    const list = (
      (statusInternal === "Online" ? doNotConnect : connectOnly) || ""
    )
      .trim()
      .split(";");
    setCallers(list);
  };

  useEffect(() => {
    applyCallers();
  }, [connectOnly, doNotConnect, statusInternal]);

  useEffect(() => {
    setReason(currentUnavailabilityExplanation || "");
  }, [currentUnavailabilityExplanation]);

  useEffect(() => {
    setOtherReason(currentUnavailabilityExplanationOther || "");
  }, [currentUnavailabilityExplanationOther]);

  useEffect(() => {
    setNote(currentStatusNote || "");
  }, [currentStatusNote]);

  useEffect(() => {
    setTemplateIdInternal(callStatusTemplateId || null);
    if (callStatusTemplateId) {
      setStatusInternal("Template");
    }
  }, [callStatusTemplateId]);

  // End advanced listen

  const { formatDateStringInMyTimeZone, formatDateObjInMyTimeZone } =
    useFormatDateInMyTimeZone();

  const expiresAtForLabel = useMemo(() => {
    let exp = null;
    if (statusExpireSelect) {
      if (
        !!statusExpireSelect &&
        !["custom", "reset"].includes(statusExpireSelect)
      ) {
        exp = dayjs().add(parseInt(statusExpireSelect), "hours");
      } else if (statusExpireSelect === "custom" && !!customExpire) {
        exp = dayjs(customExpire);
      }
    } else if (
      statusExpireAtUTC &&
      (statusInternal !== "Template"
        ? status === statusInternal
        : callStatusTemplateId === templateIdInternal)
    ) {
      exp = dayjs(statusExpireAtUTC);
    }

    return exp ? formatDateObjInMyTimeZone(exp) : null;
  }, [
    customExpire,
    statusExpireAtUTC,
    statusExpireSelect,
    status,
    statusInternal,
    callStatusTemplateId,
    templateIdInternal,
  ]);

  const expiresAtForWidget = useMemo(() => {
    if (statusExpireAtUTC) {
      return formatDateStringInMyTimeZone(statusExpireAtUTC);
    }

    return null;
  }, [statusExpireAtUTC, formatDateStringInMyTimeZone]);

  // const initStatusResetTimeout = (
  //   statusExpireAtUTC: string,
  //   revertStatus: string
  // ) => {
  //   if (resetStatusTimeout.current) {
  //     clearTimeout(resetStatusTimeout.current);
  //   }
  //   const revert = () => {
  //     setStatus(revertStatus);
  //     setStatusExpireAtUTC(null);
  //     if (activeCompanyObject) {
  //       updateStatus(
  //         activeCompanyObject.id,
  //         activeCompanyObject.contactId,
  //         revertStatus,
  //         null,
  //         null
  //       );
  //     }
  //   };
  //   if (statusExpireAtUTC) {
  //     if (dayjs(statusExpireAtUTC) <= dayjs().utc()) {
  //       revert();
  //     } else {
  //       resetStatusTimeout.current = setTimeout(() => {
  //         revert();
  //       }, dayjs(statusExpireAtUTC).diff(dayjs().utc()));
  //     }
  //   }
  // };

  useEffect(() => {
    fetchStatus().then(() => {
      setIsInitLoading(false);
    });
  }, []);

  useEffect(() => {
    if (activeCompanyObject) {
      fetchStatus().then(() => {
        setIsInitLoading(false);
      });
    }
    setTimeout(() => setRefreshCounter((rc) => rc + 1), REFRESH_INTERVAL_DELAY);
  }, [refreshCounter, activeCompanyObject]);

  const fetchStatus = async (force = true) => {
    if (activeCompanyObject) {
      await fetch(activeCompanyObject.id, activeCompanyObject.contactId, force);

      // initStatusResetTimeout(
      //   contactRes.data.statusExpirationDate,
      //   contactRes.data.takeCalls === "Yes" ? "Offline" : "Online"
      // );
    }
  };

  const validate = () => {
    if (
      !transferTo?.trim() &&
      !transferToInternal.trim() &&
      statusInternal === "Online"
    ) {
      setErrors({
        ...errors,
        transferToInternal: "Transfer to? is a required field",
      });
      return false;
    }
    if (reason === "Other" && !otherReason.trim()) {
      setErrors({
        ...errors,
        otherReason:
          "How would you want us to explain your unavailability? (Other) is a required field",
      });
      return false;
    }
    if (statusInternal === "Template" && !templateIdInternal) {
      setErrors({
        ...errors,
        templateIdInternal: "Template is a required field",
      });
      return false;
    }
    if (isSaveAsTemplate && !createTemplateName.trim()) {
      setErrors({
        ...errors,
        createTemplateName: "Template Name is a required field",
      });
      return false;
    }
    const customStartDate = customStart ? dayjs(customStart) : null;
    const nowDate = dayjs().add(5, "minute");
    const maxDate = dayjs().add(1, "month");
    const nextExpireDate = nextExpireAtUTC ? dayjs(nextExpireAtUTC) : null;

    if ("later" === statusStartSelect) {
      if (!customStartDate) {
        setErrors({
          ...errors,
          customStart: "Later start date/time is a required field.",
        });
        return false;
      }

      if (nextExpireDate && nextExpireDate.isBefore(customStartDate)) {
        setErrors({
          ...errors,
          nextExpireAtUTC:
            "Expire date must be greater than the start date/time.",
        });
        return false;
      }

      if (customStartDate.isBefore(nowDate)) {
        setErrors({
          ...errors,
          customStart:
            "Later start date/time must be greater than the current time + 5 minutes.",
        });
        return false;
      }
    }

    if ("custom" === statusExpireSelect && !nextExpireDate) {
      setErrors({
        ...errors,
        nextExpireAtUTC: "Custom date is a required field.",
      });
      return false;
    }

    if (nextExpireDate) {
      if (nowDate.isAfter(nextExpireDate)) {
        setErrors({
          ...errors,
          nextExpireAtUTC:
            "Custom date should be greater than current time + 5 minutes",
        });
        return false;
      }

      if (nextExpireDate.isAfter(maxDate)) {
        setErrors({
          ...errors,
          nextExpireAtUTC: "Statuses can't exceed more than 1 month.",
        });
        return false;
      }
    }

    return true;
  };

  const currentTemplate = useMemo(() => {
    if (templateIdInternal) {
      return (
        (templates?.data || []).find((t) => t.id === templateIdInternal) || null
      );
    }
    return null;
  }, [templateIdInternal, templates]);

  const nextExpireAtUTC = useMemo(() => {
    let expire = null;
    if (
      !!statusExpireSelect &&
      !["custom", "reset"].includes(statusExpireSelect)
    ) {
      if (customStart) {
        expire = dayjs(customStart).add(parseInt(statusExpireSelect), "hours");
      } else {
        expire = dayjs().add(parseInt(statusExpireSelect), "hours");
      }
    } else if (statusExpireSelect === "custom" && !!customExpire) {
      expire = dayjs(customExpire);
    } else if (statusExpireSelect !== "reset" && statusExpireAtUTC) {
      expire = dayjs(statusExpireAtUTC);
    }
    return expire ? expire.utc().format() : null;
  }, [statusExpireSelect, customExpire, statusExpireAtUTC]);

  const [callStatusConflict, setCallStatusConflict] =
    useState<Array<CallStatusSchedulerType> | null>(null);
  const handleSucces = () => {
    setIsLoading(false);
    setErrors({});
  };
  const { mutateAsync } = useAddCallStatusSchedulers({
    currContactId: activeCompanyObject?.contactId,
    onSuccess: handleSucces,
  });

  const onSave = async () => {
    // Validation
    if (!validate()) return false;

    if (isSaveAsTemplate) {
      await mutateCreateStatusTemplate({
        name: createTemplateName,
        takeCalls: statusInternal === "Online",
        connectOnly:
          statusInternal === "Offline" ? callers.join(";") : undefined,
        doNotConnect:
          statusInternal === "Online" ? callers.join(";") : undefined,
        unavailabilityExplanation:
          statusInternal === "Offline" ? reason || "Unavailable" : undefined,
        unavailabilityExplanationOther:
          statusInternal === "Offline" && reason === "Other"
            ? otherReason
            : undefined,
        statusNote: note,
      });
      return;
    }

    // Making redux state update before api call to avoid ui delays, if any api errors may happen need to move to try block end below
    if ("later" !== statusStartSelect) {
      setStatus(
        !templateIdInternal
          ? statusInternal
          : currentTemplate?.takeCalls === "true"
          ? "Online"
          : "Offline"
      );
      setTransferTo(transferToInternal || null);
      setStatusExpireAtUTC(nextExpireAtUTC);
    }

    setIsLoading(true);

    if (activeCompanyObject) {
      const joinedCallers = callers
        .map((c) => c.trim())
        .filter((c) => c !== "")
        .join(";");

      if ("later" === statusStartSelect) {
        const payload: Partial<AddScheduledCallStatusType> = {
          changeStatusTo: "Online" === statusInternal ? "Yes" : "No",
          connectOnly: "Online" === statusInternal ? undefined : joinedCallers,
          doNotConnect: "Online" === statusInternal ? joinedCallers : undefined,
          unavailabilityExplanation:
            "Offline" === statusInternal ? reason || "Unavailable" : undefined,
          statusNote: note || undefined,
        };
        if (customStart) {
          payload.applyStatusOn = new Date(
            customStart
          ).toISOString() as TDateISO;
        }
        if (nextExpireAtUTC) {
          payload.revertStatusOn = new Date(
            nextExpireAtUTC
          ).toISOString() as TDateISO;
        }
        if ("Other" === reason) {
          payload.unavailabilityExplanationOther =
            otherReason?.trim() || undefined;
        }

        try {
          await mutateAsync(payload as AddScheduledCallStatusType);
        } catch (error) {
          const response = (error as AxiosError).response;
          const status = response?.status;
          const message = response?.data
            ?.message as Array<CallStatusSchedulerType>;

          if (status === 409 && message.length >= 2) {
            setCallStatusConflict(message);
            setIsLoading(false);
            onClose();
            return;
          }

          return false;
        }

        return true;
      }

      updateStatus(
        activeCompanyObject.id,
        activeCompanyObject.contactId,
        statusInternal,
        nextExpireAtUTC,
        transferToInternal || null,
        statusInternal === "Online" ? null : joinedCallers,
        statusInternal === "Online" ? joinedCallers : null,
        statusInternal === "Offline" ? reason || "Unavailable" : null,
        otherReason,
        note,
        templateIdInternal
      ).then(() => {
        fetchStatus().then(() => {
          setIsLoading(false);
          refetchStatusPage();
          setErrors({});
        });
      });
    }
    return true;
  };

  const onStatusSwitch = (
    newStatus: StatusLabelKeysType,
    newTransferTo: null | string = null
  ) => {
    if (!transferTo && !newTransferTo && newStatus === "Online") {
      onOpenTransferToModal();
      return;
    }
    onClose();
    setStatusInternal(newStatus);
    setStatus(newStatus);
    if (newTransferTo) {
      setTransferTo(newTransferTo);
    }
    setStatusExpireAtUTC(null);
    setTemplateIdInternal(null);
    if (newStatus === "Offline") {
      setReason("Unavailable");
    }

    setIsLoading(true);
    if (activeCompanyObject) {
      updateStatus(
        activeCompanyObject.id,
        activeCompanyObject.contactId,
        newStatus,
        null,
        newTransferTo,
        null,
        null,
        newStatus === "Offline" ? "Unavailable" : null,
        null,
        null
      ).then(() => {
        setIsLoading(false);
        refetchStatusPage();
      });
    }
  };

  // Save always shown due to ACA-1543
  // const isSaveShown = useMemo(() => {
  //   const isCallersChanged =
  //     callers.map((c) => c.trim()).join(";") !==
  //     (statusInternal === "Online" ? doNotConnect : connectOnly);
  //   return (
  //     !!statusExpireSelect ||
  //     statusInternal !== status ||
  //     isCallersChanged ||
  //     reason !== currentUnavailabilityExplanation ||
  //     otherReason !== (currentUnavailabilityExplanationOther || "") ||
  //     note !== (currentStatusNote || "")
  //   );
  // }, [
  //   statusExpireSelect,
  //   statusInternal,
  //   status,
  //   callers,
  //   doNotConnect,
  //   connectOnly,
  //   reason,
  //   currentUnavailabilityExplanation,
  //   otherReason,
  //   currentUnavailabilityExplanationOther,
  //   note,
  //   currentStatusNote,
  // ]);

  const tooltipContent = useMemo(() => {
    const lines = [];
    let text = "";
    if (status === "Offline") {
      if (
        currentUnavailabilityExplanation === "Other" &&
        currentUnavailabilityExplanationOther
      ) {
        text += `${currentUnavailabilityExplanationOther} - `;
      }
      if (
        currentUnavailabilityExplanation &&
        currentUnavailabilityExplanation !== "Other" &&
        currentUnavailabilityExplanation !== "Unavailable"
      ) {
        text += `${currentUnavailabilityExplanation} - `;
      }
    }

    text += status === "Online" ? "Available" : "Unavailable";
    lines.push(text);

    if (status === "Online") {
      if (doNotConnect) {
        lines.push(`Do not connect: ${doNotConnect.split(";").join(", ")}`);
      }
    } else {
      if (connectOnly) {
        lines.push(`Connect only: ${connectOnly.split(";").join(", ")}`);
      }
    }

    if (currentStatusNote) {
      lines.push(`Note: ${currentStatusNote}`);
    }
    lines.push(
      expiresAtForWidget
        ? `Expires at ${expiresAtForWidget}`
        : "Does not expire"
    );
    return (
      <Stack spacing=".5">
        {lines.map((line) => (
          <Text key={`status-tooltip-line-${line}`}>{line}</Text>
        ))}
      </Stack>
    );
  }, [
    status,
    connectOnly,
    doNotConnect,
    currentUnavailabilityExplanation,
    currentUnavailabilityExplanationOther,
    currentStatusNote,
    expiresAtForWidget,
  ]);

  const [isMobile] = useMediaQuery(`(max-width: ${breakpoints.md})`);

  const onSaveTransferToModal = (newTransferTo: string) => {
    onStatusSwitch("Online", newTransferTo);
  };

  const getCurrentStatusLabels = (status: string) => {
    if (status === "Emergency Calls Only") {
      return status;
    }

    return statusLabels[status as StatusLabelKeysType];
  };

  return (
    <>
      {/* For the cases when status edit page opened from status widget */}
      <Popover
        offset={isMobile ? [-25, 35] : [0, 15]}
        placement={"bottom-end"}
        isOpen={isOpen && !isOpenExternal}
        onOpen={onOpen}
        onClose={onModalCloseAndReset}
        closeOnBlur={false}
      >
        <>
          <StatusMenuWidget
            isInitLoading={isInitLoading}
            status={status}
            onStatusSwitch={onStatusSwitch}
            currentStatusLabel={getCurrentStatusLabels(status)}
            expiresAtText={expiresAtForWidget}
            tooltipContent={tooltipContent}
          />
          <PopoverContent ref={popoverContextRef}>
            <StatusMenuContext
              isOpen={isOpen}
              isOpenExternal={isOpenExternal}
              onClose={onModalCloseAndReset}
              statusLabels={statusLabels}
              status={status}
              statusInternal={statusInternal}
              setStatusInternal={setStatusInternal}
              isLoading={isLoading || isCreateStatusTemplateLoading}
              statusStartSelect={statusStartSelect}
              setStatusStartSelect={setStatusStartSelect}
              statusExpireSelect={statusExpireSelect}
              setStatusExpireSelect={setStatusExpireSelect}
              statusExpireAtUTC={statusExpireAtUTC}
              setStatusExpireAtUTC={setStatusExpireAtUTC}
              expiresAtForLabel={expiresAtForLabel}
              customStart={customStart}
              setCustomStart={setCustomStart}
              customExpire={customExpire}
              setCustomExpire={setCustomExpire}
              isTransferToProvided={!!transferTo?.trim()}
              transferToInternal={transferToInternal}
              setTransferToInternal={setTransferToInternal}
              templates={templates}
              isTemplatesLoading={isTemplatesLoading}
              callStatusTemplateId={callStatusTemplateId}
              templateIdInternal={templateIdInternal}
              setTemplateIdInternal={setTemplateIdInternal}
              isSaveAsTemplate={isSaveAsTemplate}
              setIsSaveAsTemplate={setIsSaveAsTemplate}
              createTemplateName={createTemplateName}
              setCreateTemplateName={setCreateTemplateName}
              // Advanced
              callers={callers}
              setCallers={setCallers}
              reason={reason}
              setReason={setReason}
              otherReason={otherReason}
              setOtherReason={setOtherReason}
              note={note}
              setNote={setNote}
              // Other
              errors={errors}
              setErrors={setErrors}
              onSave={onSave}
              isOpenAdvance={isOpenAdvance}
              onToggleAdvance={onToggleAdvance}
              isSaveShown={true}
            />
          </PopoverContent>
        </>
      </Popover>
      {/* For the cases when status edit page opened from outside of status widget */}
      <Modal isOpen={isOpenExternal} onClose={onClose} autoFocus={false}>
        <ModalOverlay />
        <ModalContent ref={modalContextRef}>
          <ModalBody>
            <StatusMenuContext
              isOpen={isOpen}
              isOpenExternal={isOpenExternal}
              onClose={onModalCloseAndReset}
              statusLabels={statusLabels}
              status={status}
              statusInternal={statusInternal}
              setStatusInternal={setStatusInternal}
              isLoading={isLoading || isCreateStatusTemplateLoading}
              statusStartSelect={statusStartSelect}
              setStatusStartSelect={setStatusStartSelect}
              statusExpireSelect={statusExpireSelect}
              setStatusExpireSelect={setStatusExpireSelect}
              statusExpireAtUTC={statusExpireAtUTC}
              setStatusExpireAtUTC={setStatusExpireAtUTC}
              expiresAtForLabel={expiresAtForLabel}
              customStart={customStart}
              setCustomStart={setCustomStart}
              customExpire={customExpire}
              setCustomExpire={setCustomExpire}
              isTransferToProvided={!!transferTo?.trim()}
              transferToInternal={transferToInternal}
              setTransferToInternal={setTransferToInternal}
              templates={templates}
              isTemplatesLoading={isTemplatesLoading}
              callStatusTemplateId={callStatusTemplateId}
              templateIdInternal={templateIdInternal}
              setTemplateIdInternal={setTemplateIdInternal}
              isSaveAsTemplate={isSaveAsTemplate}
              setIsSaveAsTemplate={setIsSaveAsTemplate}
              createTemplateName={createTemplateName}
              setCreateTemplateName={setCreateTemplateName}
              // Advanced
              callers={callers}
              setCallers={setCallers}
              reason={reason}
              setReason={setReason}
              otherReason={otherReason}
              setOtherReason={setOtherReason}
              note={note}
              setNote={setNote}
              // Other
              errors={errors}
              setErrors={setErrors}
              onSave={onSave}
              isOpenAdvance={isOpenAdvance}
              onToggleAdvance={onToggleAdvance}
              isSaveShown={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <TransferToModal
        isOpen={isOpenTransferToModal}
        onClose={onCloseTransferToModal}
        onSave={onSaveTransferToModal}
      />
      {callStatusConflict && (
        <CallStatusConflictModal
          handleClosePrevModal={() => onClose()}
          callStatusConflict={callStatusConflict}
          currContactId={activeCompanyObject?.contactId}
        />
      )}
    </>
  );
};

export default StatusMenu;
