import { useToast } from "@chakra-ui/react";
import axios from "src/services/network/axios";
import { useMutation } from "react-query";
import { useCompanySelection } from "src/store/companySelectionState";
import { useQueryClient } from "react-query";
import getCallStatusSchedulersKeys from "src/hooks/queries/account/useCallStatusSchedulersQuery/getCallStatusSchedulersKeys";
import getCurrentCallStatusKeys from "src/hooks/queries/account/useCurrentCallStatusQuery/getCurrentCallStatusKeys";
import { CallStatusSchedulerType } from "src/hooks/queries/account/useCallStatusSchedulersQuery";

export type CallStatusResolvedConflictType = CallStatusSchedulerType & {
  isPreserved?: boolean;
};

export type CallStatusResolvedSetType = [
  CallStatusResolvedConflictType,
  CallStatusResolvedConflictType
];

const resolveCallStatusConflict =
  (companyId: string, contactId: string) =>
  async (callStatusSet: CallStatusResolvedSetType) => {
    const body = callStatusSet;

    // {{baseUrl}}/companies/:companyId/contacts/:contactId/resolveCallStatusSchedulerConflict
    const response = await axios.patch(
      `/companies/${companyId}/contacts/${contactId}/resolveCallStatusSchedulerConflict`,
      body
    );

    return response;
  };

type ParamType = {
  onSuccess?: (arg: any) => void;
  currContactId?: string;
};

const useResolveCallStatusConflict = (options?: ParamType) => {
  const queryClient = useQueryClient();
  const companySelection = useCompanySelection();
  const activeCompany = companySelection!.activeCompany as string;
  const contactId =
    options?.currContactId ??
    (companySelection!.activeCompanyObject?.contactId as string);

  const toast = useToast();

  const queryKey = getCallStatusSchedulersKeys({
    companyId: activeCompany,
    contactId,
  });

  const currentCallStatusQueryKey = getCurrentCallStatusKeys({
    companyId: activeCompany,
    contactId,
  });

  const mutation = useMutation(
    resolveCallStatusConflict(activeCompany, contactId),
    {
      onSuccess(response) {
        if (response) {
          options?.onSuccess && options.onSuccess(response);
          queryClient.invalidateQueries(queryKey);
          queryClient.invalidateQueries(currentCallStatusQueryKey);

          // refetch();

          toast({
            title: "Resolve Call Status Conflict Success",
            description:
              "Scheduled call status conflict was resolved successfully!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
        }
      },
      onError() {
        toast({
          title: "Resolve Call Status Conflict Error",
          description: "Failed to resolve Call Status Conflict.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      },
    }
  );

  return mutation;
};

export default useResolveCallStatusConflict;
