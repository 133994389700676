import chainGet from "src/services/network/chainGet";
import { useCompanySelection } from "src/store/companySelectionState";

const useIsMainAccountType = () => {
  const { activeCompanyObject } = useCompanySelection();
  const accountType = chainGet(activeCompanyObject?.type, "");

  return "Customer" === accountType;
};

export default useIsMainAccountType;
