import entities from "src/constants/entities";

export type QueryKeysType = [
  typeof entities.CALL_STATUS_SCHEDULER,
  OptionsType
];

export type OptionsType = {
  companyId: string;
  contactId: string;
};

const getCallStatusSchedulersKeys = (options: OptionsType): QueryKeysType => [
  entities.CALL_STATUS_SCHEDULER,
  {
    companyId: options.companyId,
    contactId: options.contactId,
  },
];

export default getCallStatusSchedulersKeys;
