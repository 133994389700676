import { FC, useMemo, useCallback, useEffect } from "react";
import createContextSet from "src/utils/createContextSet";
import { useForm, UseFormSetValue } from "react-hook-form";
import ActivityQueryTypes from "src/constants/activityQueryTypes";
import notNull from "src/utils/notNull";
import { useNavigate, useSearchParams } from "react-router-dom";
import parseDate from "src/utils/parseDate";
import dayjs from "dayjs";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";

export const MAX_DAYS_PERIOD = 30;

// I can add other context fields here as needed
export type AllActivitiesFilterType = {
  startDateFilter: Date | null;
  endDateFilter: Date | null;
  queryFilter: string;
  startDateUnixTime: number | null;
  endDateUnixTime: number | null;
  activityTypeFilter: ActivityQueryTypes | null;
  hasActiveFilter: boolean;
  readStatusFilter: boolean | null;
  isTransferredFilter: boolean | null;
  isSkipCleanup: boolean | null;
};

export type ActivitiesContextReturnType = {
  setValue: UseFormSetValue<
    Omit<
      AllActivitiesFilterType,
      "startDateUnixTime" | "endDateUnixTime" | "hasActiveFilter"
    >
  >;
} & AllActivitiesFilterType & {
    clearFilterByType: (arg: FilterTypes) => void;
    readStatusFilter: boolean | null;
    isTransferredFilter: boolean | null;
    setMultipleValues: (newValues: Partial<FormValuesType>) => void;
  };

const [useActivitiesFilterCtx, ActivitiesFilterCtxProvider] =
  createContextSet<ActivitiesContextReturnType>();

const initialValues = {
  startDateFilter: null as Date | null,
  endDateFilter: null as Date | null,
  queryFilter: "",
  activityTypeFilter: null as ActivityQueryTypes | null,
  readStatusFilter: null as boolean | null,
  isTransferredFilter: null as boolean | null,
  isSkipCleanup: null as boolean | null,
};

type FormValuesType = typeof initialValues;

export enum FilterTypes {
  QUERY = "query",
  START_DATE = "startDate",
  END_DATE = "endDate",
  TYPE = "type",
  READ_STATUS = "readStatus",
  IS_TRANSFERRED = "isTransferred",
}

export const addHoursAndMinutes = (
  date: Date,
  hours: number,
  minutes: number
) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  newDate.setMinutes(newDate.getMinutes() + minutes);
  return newDate;
};

// I use this context is mainly to avoid prop drilling
const ActivitiesFilterContextProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const startDateParam = parseDate(searchParams.get("startDate"));
  const endDateParam = parseDate(searchParams.get("endDate"));

  const removeSearchParams = useCallback(
    (params: string[] | null = null) => {
      let removeParams = false;
      // Create a new instance so that changes is detected
      const updatedSearchParams = new URLSearchParams(searchParams);

      if (!params) {
        params = ["startDate", "endDate"];
      }
      params.forEach((param: string) => {
        if (updatedSearchParams.has(param)) {
          updatedSearchParams.delete(param);
          removeParams = true;
        }
      });

      if (removeParams) {
        navigate(
          { search: decodeURIComponent(updatedSearchParams.toString()) },
          { replace: true }
        );
      }
    },
    [searchParams, navigate]
  );

  const hookFormVals = useForm({
    defaultValues: {
      ...initialValues,
      startDateFilter: startDateParam || initialValues.startDateFilter,
      endDateFilter: endDateParam || initialValues.endDateFilter,
    },
  });

  const { watch, setValue, reset, getValues } = hookFormVals;
  const startDateFilter = watch("startDateFilter");
  const endDateFilter = watch("endDateFilter");
  const queryFilter = watch("queryFilter");
  const activityTypeFilter = watch("activityTypeFilter");
  const readStatusFilter = watch("readStatusFilter");
  const isTransferredFilter = watch("isTransferredFilter");
  const isSkipCleanup = watch("isSkipCleanup");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearFilterByType = (filterType: FilterTypes) => {
    let removeParams = false;
    if (filterType === FilterTypes.QUERY) {
      setValue("queryFilter", "");
      removeParams = true;
    }
    if (filterType === FilterTypes.TYPE) {
      setValue("activityTypeFilter", null);
      removeParams = true;
    }
    if (filterType === FilterTypes.START_DATE) {
      setValue("startDateFilter", null);
      removeParams = true;
    }
    if (filterType === FilterTypes.END_DATE) {
      setValue("endDateFilter", null);
      removeParams = true;
    }
    if (filterType === FilterTypes.READ_STATUS) {
      setValue("readStatusFilter", null);
      removeParams = true;
    }
    if (filterType === FilterTypes.IS_TRANSFERRED) {
      setValue("isTransferredFilter", null);
      removeParams = true;
    }
    if (removeParams) {
      removeSearchParams();
    }
  };

  const { getUtcFromDate } = useFormatDateInMyTimeZone();
  const startDateUnixTime = startDateFilter
    ? getUtcFromDate(dayjs(startDateFilter).startOf("day"))
    : null;
  const endDateUnixTime = endDateFilter
    ? getUtcFromDate(dayjs(endDateFilter).endOf("day"))
    : null;

  const hasActiveFilter =
    !![startDateFilter, endDateFilter, queryFilter, activityTypeFilter].find(
      (item) => Boolean(item)
    ) ||
    notNull(readStatusFilter) ||
    notNull(isTransferredFilter);

  const setMultipleValues = useCallback(
    (newValues: Partial<FormValuesType>) => {
      const prevVals = getValues();
      reset({
        ...prevVals,
        ...newValues,
      });
      removeSearchParams();
    },
    [getValues, reset, removeSearchParams]
  );

  const contextValue = useMemo(
    () => ({
      hasActiveFilter,
      startDateFilter,
      startDateUnixTime,
      endDateUnixTime,
      endDateFilter,
      queryFilter,
      setValue,
      activityTypeFilter,
      clearFilterByType,
      readStatusFilter,
      isTransferredFilter,
      setMultipleValues,
      isSkipCleanup,
    }),
    [
      hasActiveFilter,
      startDateFilter,
      startDateUnixTime,
      endDateUnixTime,
      endDateFilter,
      queryFilter,
      setValue,
      activityTypeFilter,
      clearFilterByType,
      readStatusFilter,
      isTransferredFilter,
      setMultipleValues,
      isSkipCleanup,
    ]
  );

  useEffect(() => {
    if (!startDateParam) {
      removeSearchParams(["startDate"]);
    } else {
      if (!dayjs(startDateFilter).isSame(dayjs(startDateParam))) {
        setValue("startDateFilter", startDateParam);
      }
    }
    if (!endDateParam) {
      removeSearchParams(["endDate"]);
    } else {
      if (!dayjs(endDateFilter).isSame(dayjs(endDateParam))) {
        setValue("endDateFilter", endDateParam);
      }
    }
  }, [startDateParam, endDateParam, removeSearchParams]);

  useEffect(() => {
    if (true !== isSkipCleanup) {
      if (!startDateFilter && !endDateFilter) {
        setValue(
          "startDateFilter",
          dayjs().subtract(MAX_DAYS_PERIOD, "day").toDate()
        );
      } else if (!startDateFilter && endDateFilter) {
        setValue(
          "startDateFilter",
          dayjs(endDateFilter).subtract(MAX_DAYS_PERIOD, "day").toDate()
        );
      } else if (startDateFilter && !endDateFilter) {
        const minStartDate = dayjs().subtract(MAX_DAYS_PERIOD, "day").toDate();
        if (dayjs(startDateFilter).isBefore(minStartDate)) {
          setValue("startDateFilter", minStartDate);
        }
      }
    } else {
      setValue("startDateFilter", null);
    }
  }, [isSkipCleanup]);

  return (
    <ActivitiesFilterCtxProvider value={contextValue}>
      {children}
    </ActivitiesFilterCtxProvider>
  );
};

export default ActivitiesFilterContextProvider;
export const useActivityFilterContext = () => useActivitiesFilterCtx();
