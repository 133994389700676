import { useCompanySelection } from "src/store/companySelectionState";
import useDataWithPagination, {
  IPaginationDefaults,
} from "src/hooks/queries/paginationQuery/useDataWithPagination";
import { QueryKeyType } from "src/hooks/queries/paginationQuery/useDataWithPagination";
import type { QueryKeyPaginationType } from "src/hooks/queries/paginationQuery/useDataWithPagination";
import { QueryFunction } from "react-query";
import entities from "src/constants/entities";
import { PaginationQueryType } from "src/types";
import axios from "src/services/network/axios";
import { useStatusTemplateFilterContext } from "src/contexts/StatusTemplateFilterContext";
import notNull from "src/utils/notNull";
import chainGet from "src/services/network/chainGet";
import { SortEnum } from "src/hooks/queries/activities/activitiesKeys";

//

export type CallStatusTemplateType = {
  unavailabilityExplanationOther: string;
  unavailabilityExplanation: string;
  transferPhone: string | null;
  takeCalls: string;
  statusNote: string;
  id: string;
  doNotConnect: string;
  connectOnly: string;
  name: string;
  createdDate: string;
};

export type StatusTemplatesResponseType = {
  data: CallStatusTemplateType[];
  pagination: PaginationQueryType;
};

export type OptionsType = {
  companyId: string;
  contactId: string;
  sort: {
    sortBy: string;
    sortType: SortEnum;
  };
  filters: {
    queryFilter: string;
    isTakingCallsFilter: boolean | null;
  };
};

export const getCallStatusTemplateKeys = (
  options: OptionsType
): QueryKeyType<OptionsType> => [
  entities.CALL_STATUS_TEMPLATES,
  {
    companyId: options.companyId,
    contactId: options.contactId,
    sort: options.sort,
    filters: {
      queryFilter: chainGet(options.filters?.queryFilter, ""),
      isTakingCallsFilter: options.filters?.isTakingCallsFilter,
    },
  },
];

const fetchStatusTemplatesQuery: QueryFunction<
  StatusTemplatesResponseType,
  QueryKeyPaginationType<OptionsType>
> = async ({ queryKey }) => {
  const options = queryKey[1];
  const companyId = options.companyId;
  const contactId = options.contactId;

  const sortBy = options.sort.sortBy;
  const sortType = options.sort.sortType;

  const queryFilter = options.filters?.queryFilter;
  const isTakingCallsFilter = options.filters?.isTakingCallsFilter;
  const page = options.currentPage;
  const count = options.size;

  // {{baseUrl}}/companies/0018N00000CXthyQAD/contacts/0038N00000Al70KQAR/callStatusTemplates?limit=10&page=1&takeCalls=true
  let initialUrl = `/companies/${companyId}/contacts/${contactId}/callStatusTemplates?limit=${count}&page=${page}&sortBy=${sortBy}&sortType=${sortType}`;

  const getEndpointUrl = () => {
    if (queryFilter) {
      initialUrl += `&query=${queryFilter}`;
    }

    if (notNull(isTakingCallsFilter)) {
      initialUrl += `&takeCalls=${isTakingCallsFilter}`;
    }

    return `${initialUrl}`;
  };

  const response = await axios.get(getEndpointUrl());
  return response?.data;
};

const useContactCallStatusTemplates = (
  paginationDefaults?: IPaginationDefaults,
  currContactId?: string
) => {
  const companySelection = useCompanySelection();
  const activeCompany = companySelection!.activeCompany;
  const companyId = companySelection!.activeCompany as string;
  const contactId =
    currContactId ??
    (companySelection!.activeCompanyObject?.contactId as string);
  // -
  const filtersContext = useStatusTemplateFilterContext();
  const { queryFilter, isTakingCallsFilter, sortState } = filtersContext;

  const [sortOption] = sortState;
  const sortBy = sortOption.sortBy;
  const sortType = sortOption.sortType;

  const contactCallStatusTemplatesKeys = getCallStatusTemplateKeys({
    companyId,
    contactId,
    sort: {
      sortBy,
      sortType,
    },
    filters: {
      queryFilter,
      isTakingCallsFilter,
    },
  }) as QueryKeyType<OptionsType>;

  const dataWithPagination = useDataWithPagination(
    contactCallStatusTemplatesKeys,
    fetchStatusTemplatesQuery,
    !!activeCompany,
    paginationDefaults
  );

  return { ...dataWithPagination, sortState };
};

export default useContactCallStatusTemplates;

// "unavailabilityExplanationOther": "One more",
// "unavailabilityExplanation": "Unavailable",
// "transferPhone": null,
// "takeCalls": "true",
// "statusNote": "template status note",
// "id": "a0B8N000000NwTVUA0",
// "doNotConnect": "+9000000000000",
// "connectOnly": "+300000000000",
// "name": "My template"
