import { useEffect, useState } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";
import { CallStatusSchedulerType } from "src/hooks/queries/account/useCallStatusSchedulersQuery";
import ScheduledStatusConflictCard from "./ScheduledStatusConflictCard";
import theme from "src/styles/theme";
import useResolveCallStatusConflict from "src/hooks/mutations/account/useResolveCallStatusConflict";
import type { CallStatusResolvedSetType } from "src/hooks/mutations/account/useResolveCallStatusConflict";

const headingFont = theme.fonts.heading;

type Props = {
  handleClosePrevModal: () => void;
  // 0 - new,  1 - exisiting
  callStatusConflict: Array<CallStatusSchedulerType>;
  currContactId?: string;
};

const CallStatusConflictModal = ({
  handleClosePrevModal,
  callStatusConflict,
  currContactId,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (callStatusConflict) {
      onOpen();
      return;
    }
    onClose();
  }, [callStatusConflict]);

  const { mutateAsync: resolveConflict } = useResolveCallStatusConflict({
    currContactId,
  });

  const selectedStatusState = useState<CallStatusSchedulerType | null>(null);
  const [selectedStatus] = selectedStatusState;
  const [step, setStep] = useState<1 | 2>(1);

  const lastCallStatusItem = callStatusConflict[callStatusConflict.length - 1];

  const renderStep = (step: 1 | 2) => {
    if (step === 1) {
      return (
        <ModalBody
          display="grid"
          gridTemplateColumns={{
            base: "repeat(1, minmax(0, 1fr))",
            xl: "repeat(2, minmax(0, 1fr))",
          }}
          // maxW={{

          //   xl: "100%",
          // }}
          px={{ base: 0, sm: 4, lg: 8 }}
          pb={8}
          bg="#F2F2F2"
          w="100%"
          columnGap={4}
          rowGap={0}
        >
          <Flex align="center" flexDir="column" flexGrow={1} rowGap={2} key={0}>
            <Text fontSize="22px" fontWeight="bold">
              New
            </Text>

            <ScheduledStatusConflictCard
              lastCallStatusItem={lastCallStatusItem}
              selectedStatusState={selectedStatusState}
              scheduledStatus={lastCallStatusItem as CallStatusSchedulerType}
            />
          </Flex>
          <Flex
            align="center"
            flexDir="column"
            rowGap={2}
            flexGrow={1}
            key={1}
            mt={{ base: 2, xl: 0 }}
          >
            <Text fontSize="22px" fontWeight="bold">
              Existing
            </Text>

            {callStatusConflict.map((item, index) => {
              //New is always last
              if (index === callStatusConflict.length - 1) return null;

              return (
                <ScheduledStatusConflictCard
                  lastCallStatusItem={lastCallStatusItem}
                  key={item.id}
                  selectedStatusState={selectedStatusState}
                  scheduledStatus={
                    callStatusConflict[index] as CallStatusSchedulerType
                  }
                />
              );
            })}
          </Flex>
        </ModalBody>
      );
    }
  };

  const getModalMaxWidth = () => {
    return { base: "100%", csm: "627px", xl: "1070px" };
  };

  const handleClick = async () => {
    // first check if last item is selected
    const isLastItemSelected = lastCallStatusItem?.id === selectedStatus?.id;
    const isLastItemNotSelected = !isLastItemSelected;

    const callStatusResolvedConflict = callStatusConflict.map((item) => {
      if (isLastItemSelected) {
        if (lastCallStatusItem?.id === item.id) {
          return { ...item, isPreserved: true };
        }
        return item;
      }

      if (isLastItemNotSelected) {
        if (lastCallStatusItem?.id === item.id) {
          return item;
        }
        return { ...item, isPreserved: true };
      }
      return item;
    });

    const response = await resolveConflict(
      callStatusResolvedConflict as CallStatusResolvedSetType
    );

    if (response) {
      handleClosePrevModal();
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} autoFocus={false}>
        <ModalOverlay />
        <ModalContent
          borderRadius="10px"
          mx={{ base: "4%", csm: 0 }}
          px={6}
          w="100%"
          maxW={getModalMaxWidth()}
        >
          <ModalHeader px={0}>
            <Text fontFamily={headingFont} fontWeight="bold" fontSize="16px">
              CALL STATUS CONFLICT
            </Text>

            <Box fontSize="14px" fontWeight="400" mt={1}>
              <Text>
                You have statuses that overlap. Please choose which status would
                you like to keep and click on Proceed button.
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />

          {renderStep(step)}
          {/* {renderStep(2)} */}
          <ModalFooter my={4}>
            <Flex d="flex" fontSize="12px" w="100%" gap={2}>
              {step === 1 && (
                <Button
                  borderRadius="35px"
                  variant="purpleVariant"
                  color="#fff"
                  minW="126px"
                  fontSize="12px"
                  onClick={() => {
                    handleClick();
                    // setStep(2);
                  }}
                  disabled={!selectedStatus}
                >
                  Proceed
                </Button>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CallStatusConflictModal;
